const settings = {
  isSplash: true, // Cambia esto a true si deseas usar la pantalla de inicio.
  useCustomCursor: false, // Cambia esto a false si prefieres el cursor predeterminado.
  googleTrackingID: "", // "UA-174238252-2",
};

// Página de inicio
const greeting = {
  title: "¡Hola 👋!",
  title2: "Jean Franco Aramburú",
  logo_name: "JFA",
  nickname: "Francuchin",
  full_name: "Jean Franco Aramburú",
  subTitle: "Desarrollador Multifuncional, Entusiasta del Open Source 🔥. Siempre aprendiendo.",
  resumeLink:
    "https://drive.google.com/file/d/xxxx/view?usp=sharing",
  mail: "mailto:francuchin@gmail.com",
};

const socialMediaLinks = {
  github: "https://github.com/francuchin",
  linkedin: "https://www.linkedin.com/in/francuchin/",
  gmail: "francuchin@gmail.com",
  gitlab: "https://gitlab.com/francuchin",
  twitter: "https://twitter.com/francuchin",
  instagram: "https://www.instagram.com/francuchin/",
};

const skills = {
  data: [
    {
      title: "Desarrollo Backend",
      fileName: "FullStackImg",
      skills: [
        "⚡ Desarrollar interfaces de usuario altamente interactivas para tus aplicaciones web y móviles",
        "⚡ Construir el frontend de sitios web responsivos usando ReactJS",
        "⚡ Desarrollar aplicaciones móviles usando Flutter y Android",
        "⚡ Crear el backend de aplicaciones con Node, Express y Flask",
        "⚡ Integración de servicios de terceros como Firebase/AWS/Digital Ocean",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Arquitectura de Infraestructura en la Nube",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experiencia trabajando en múltiples plataformas en la nube",
        "⚡ Experiencia en alojamiento y gestión de sitios web",
        "⚡ Experiencia con Integración Continua",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Colegio de Ingeniería Shantilal Shah",
      subtitle: "Licenciatura en Tecnología de la Información",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2021 - 2024",
      descriptions: [
        "⚡ Estudié materias básicas como Aprendizaje Automático, Visión por Computadora, Desarrollo Web Avanzado.",
        "⚡ Implementé varios proyectos basados en lo que aprendí en mi curso de Ingeniería Informática.",
      ],
      website_link: "http://www.ssgec.ac.in/",
    },
    {
      title: "Colegio de Ingeniería y Tecnología Dr. S. & S. S. Ghandhy",
      subtitle: "Diploma en Tecnología de la Información",
      logo_path: "ssgandhy.png",
      alt_name: "SSGC",
      duration: "2018 - 2021",
      descriptions: [
        "⚡ Estudié materias básicas como Estructuras de Datos, DBMS, Redes, Seguridad, etc.",
        "⚡ También completé varios cursos en línea de Desarrollo Backend, Web, Desarrollo de Aplicaciones Móviles, etc.",
        "⚡ Implementé varios proyectos basados en lo que aprendí en mi curso de Ingeniería Informática.",
        "⚡ Obtuvé un promedio de 9.82 CGPA.",
      ],
      website_link: "http://www.ssgc.cteguj.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "M0001: Fundamentos de MongoDB",
      subtitle: "Universidad MongoDB",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
      alt_name: "Universidad MongoDB",
      // color_code: "#2AAFED",
      color_code: "#47A048",
    },
    {
      title: "Hackathon",
      subtitle: "Hack The Mountains",
      logo_path: "hackathon1.svg",
      certificate_link:
        "https://drive.google.com/file/d/1Y-HT1flWDfeyfnfqVr3ubNgGIcnuUEYt/view?usp=sharing",
      alt_name: "hackathon",
      color_code: "#E2405F",
    },
    {
      title: "A300: Seguridad en Atlas",
      subtitle: "Universidad MongoDB",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/12u_tkvhgB-K2TIm-RDdJOwYbQ9ccqiqA/view?usp=sharing",
      alt_name: "Universidad MongoDB",
      // color_code: "#F6B808",
      color_code: "#47A048",
    },
    {
      title: "Ciberseguridad y Ciberforense",
      subtitle: "Taller en IIT Bombay",
      logo_path: "iit.png",
      certificate_link:
        "https://drive.google.com/file/d/12uAdjQC5LfrB1ODdxqIY181ugyyQhl-I/view?usp=sharing",
      alt_name: "Taller",
      color_code: "#2AAFED",
    },
    {
      title: "MLH Local Hack Day: Build",
      subtitle: "Major League Hacking",
      logo_path: "mlh-logo.svg",
      certificate_link:
        "https://drive.google.com/file/d/1ws7g2Oepu76vDFir6SvR_emFAMSkB2ZQ/view?usp=sharing",
      alt_name: "Google",
      color_code: "#fe0037",
    },
    {
      title: "Hack20",
      subtitle: "Flutter International Hackathon",
      logo_path: "flutter.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTd8pVlHbP0RVQYtfa4OZgJYGfdiCwM6/view?usp=sharing",
      alt_name: "Flutter International Hackathon",
      color_code: "#2AAFED",
    },
    {
      title: "Experto Estudiante en Postman",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Página de Experiencia
const experience = {
  title: "Experiencia",
  subtitle: "Trabajo, Pasantías y Voluntariado",
  description:
    "Como Desarrollador Backend de Node.js, he trabajado en proyectos reales durante más de dos años. Principalmente he trabajado en proyectos por mi cuenta. Me encanta organizar talleres para compartir mis conocimientos con otros.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Experiencia Laboral",
      experiences: [
        {
          title: "Prácticas de Desarrollador de SDE",
          company: "Crest Infosystems Pvt. Ltd.",
          company_url: "https://www.crestinfosystems.com/",
          logo_path: "crest.png",
          duration: "Ene 2024 - Presente",
          location: "Surat, Gujarat",
          description: `Como pasante de SDE, mis prioridades incluyen oportunidades de aprendizaje y desarrollo profesional. Apuntando a la proficiencia en Desarrollo Backend.`,
          color: "#0071C5",
        },
        {
          title: "Desarrollador Backend de Node.js",
          company: "Valora Infotech",
          company_url: "http://valorainfotech.com/",
          logo_path: "valora.jpg",
          duration: "Oct 2020 - Dic 2022",
          location: "Surat, Gujarat",
          description: `Entrenar, supervisar y mentorizar a jóvenes empleados de desarrollo de software. Creé y gestioné el backend del panel de administración y una aplicación de juegos como desarrollador de backend de Node.js. Obtuvé conocimientos en Web Socket, Integración de Pasarela de Pago y legislación de juegos, mientras crecía mi experiencia en la industria e investigaba nuevas tecnologías.
          Enfoque en funciones de solicitudes de cambio en estrecha colaboración con el director del proyecto y los líderes de equipo.
          Desarrollé nuevas aplicaciones y mantuve las existentes.
          Aumenté la productividad y las técnicas de resolución de problemas en un 20%.
          Utilizamos principalmente PERN y MERN Stack. El 80% de mi trabajo consistía en desarrollo backend y el 20% restante en tareas relacionadas con DevOps.
          `,
          color: "#0071C5",
        },
        {
          title: "Desarrollador Full Stack (Freelancer)",
          company: "NightOwls",
          company_url: "http://nightowls.company/",
          logo_path: "nightowls.jpg",
          duration: "Sep 2020 - Oct 2020",
          location: "Trabajo desde Casa",
          description: `Mantener un contacto cercano con el cliente y el equipo al manejar solicitudes de cambio.
          Se utilizó principalmente LAMP Stack. El 40% de mi trabajo consistía en desarrollo backend, el 30% de mi trabajo consistía en diseñar esquemas de bases de datos y el 30% de mi trabajo consistía en mantener el hosting y el tráfico de usuarios en el sitio web.
          `,
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Voluntariados",
      experiences: [
        {
          title: "Líder de Comunidad",
          company: "Bauddhik-Geeks",
          company_url: "https://bauddhikgeeks.tech/",
          logo_path: "bauddhikgeeks.png",
          duration: "Sep 2021 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Ayudar a Tech-Geeks para que obtengan exposición al mundo técnico y desarrollen sus habilidades. Por lo tanto, el club de programación resalta todas las habilidades de los miembros individuales y los ayuda a desarrollar y mejorar sus habilidades de manera significativa. Ayudar a los miembros de la comunidad a aprender y crecer.",
          color: "#FBBD18",
        },
        {
          title: "Mentor de Cross Winter of Code",
          company: "CWOC",
          company_url: "https://crosswoc.ieeedtu.in/",
          logo_path: "cwoc.png",
          duration: "Feb 2021 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Las responsabilidades de mentoría consistían en ayudar a los estudiantes a planificar el proyecto, revisar problemas y solicitudes de extracción, asegurar un progreso fluido y ayudarlos cuando quedaran atascados.",
          color: "#4285F4",
        },
        {
          title: "Voluntario Estudiantil GDG",
          company: "Grupos de Desarrolladores de Google",
          company_url: "https://gdg.community.dev/",
          logo_path: "gdg.png",
          duration: "Feb 2021 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Voluntario estudiantil y miembro del Grupo de Desarrolladores de Google Surat.",
          color: "#D83B01",
        },
        {
          title: "Experto Estudiante en Postman",
          company: "Postman",
          company_url: "https://www.postman.com/",
          logo_path: "postman.png",
          duration: "Feb 2020 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Voluntario estudiantil y miembro del Grupo de Desarrolladores de Google Surat.",
          color: "#D83B01",
        },
        {
          title: "Desarrollador Estudiantil de GitHub",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Nov 2019 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Contribuir a la Comunidad de Código Abierto y Proyectos de Código Abierto.",
          color: "#040f26",
        },
        {
          title: "Guía Local de Google",
          company: "Mapas de Google",
          company_url: "https://maps.google.com/localguides/",
          logo_path: "localguide.png",
          duration: "Sep 2018 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Responsabilidades diarias de ayudar a las empresas locales a expandir su negocio al mundo. Ayudar a los usuarios escribiendo reseñas sobre diferentes lugares y espacios como tiendas, centros comerciales, etc.",
          color: "#D83B01",
        },
        {
          title: "Voluntario Estudiantil de E. F. I.",
          company: "FUNDACIÓN AMBIENTALISTA DE INDIA",
          company_url: "https://indiaenvironment.org/",
          logo_path: "efi.png",
          duration: "Abr 2017 - Presente",
          location: "Trabajo desde Casa",
          description:
            "Tomar tiempo durante los fines de semana para unirse a un grupo de constructores de nación, entusiastas de la naturaleza para conservar lo que queda.",
          color: "#5a900f",
        },
      ],
    },
  ],
};

// Página de Proyectos
const projectsHeader = {
  title: "Proyectos",
  description:
    "Mis proyectos hacen uso de una gran variedad de herramientas tecnológicas de última generación. Mi mejor experiencia es crear Proyectos de Backend de NodeJS, Scripts de Python y Proyectos de React. A continuación, algunos de mis proyectos. Tenga en cuenta que no todos los proyectos mencionados están en GitHub todavía.",
  avatar_image_path: "projects_image.svg",
};

const contactPageData = {
  contactSection: {
    title: "Contáctame",
    profile_image_path: "fakejfa.jpg",
    description:
      "Puedes contactarme en los lugares mencionados a continuación. Intentaré responderte lo más rápido posible.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "No escribo blogs con frecuencia, pero cuando hago algo asombroso, intento documentarlo para que pueda ser útil para otros. Escribo en Twitter.",
    link: "https://twitter.com/francuchin",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "AwesomeReact",
      url: "https://github.com/fictitioususer/AwesomeReact",
      description:
        "Una aplicación web para encontrar y compartir recursos, tutoriales y ejemplos para desarrolladores de React de todos los niveles.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/fictitioususer/AwesomeReact",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Demo en Vivo",
          url: "https://awesome-react-demo.herokuapp.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "1",
      name: "SocialBook",
      url: "https://github.com/fictitioususer/SocialBook",
      description:
        "Una aplicación de red social para compartir libros, reseñas y recomendaciones. Permite a los usuarios seguir a otros, crear y unirse a grupos, y descubrir nuevos libros.",
      languages: [
        {
          name: "ReactJS",
          iconifyClass: "logos-react",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "MongoDB",
          iconifyClass: "logos-mongodb-icon",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/fictitioususer/SocialBook",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Demo en Vivo",
          url: "https://socialbook-demo.herokuapp.com/",
          iconifyClass: "mdi:web",
        },
      ],
    },
    // Añade más proyectos ficticios aquí según sea necesario
  ],
};


export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
